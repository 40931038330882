import { Genre } from "@filmweb/sanity-types";
import FilmwebLink from "src/components/filmweblink/FilmwebLink";
import theme from "src/components/themes/DefaultTheme";
import { useLocationContext } from "src/lib/contexts/LocationContext";
import { type Show, type VersionTag } from "src/lib/movieinfo/movieinfotypes";
import styled, { css } from "src/lib/styles/css";

//#region [Props]
type ShowProps = {
    show: Show;
    isKinoklubbMovie?: boolean;
    genres?: Genre[];
    className?: string;
};
//#endregion



//#region [Component]
export default function Show({ show, isKinoklubbMovie = false, genres, className }: ShowProps) {
    const locationContext = useLocationContext();
    let disableShowLink = !show.ticketSaleUrl;
    const Time = isKinoklubbMovie ? SKKTime : STime;

    return <SShow to={getTicketUrl(show.ticketSaleUrl, show.theaterName!, show.movieTitle!)}
        $isKinoklubb={isKinoklubbMovie && show.isKinoklubb}
        target="_blank"
        onClick={() => !disableShowLink ? trackSale(show, genres) : false}
        className={className}
    >
        <Time >{show.showStart.substring(11, 16)}</Time>
        <SScreenLine>
            <SScreenName $isMultiLocation={hasMultipleLocations(locationContext.location)}>
                {getScreenName(show, locationContext.location)}
            </SScreenName>
            <SVersionInfo>
                {getVersionName(show)}
            </SVersionInfo>
        </SScreenLine>
    </SShow>

}
//#endregion

//#region [Other]
const EXCLUDE_TAGS = ["Norsk tekst", "2D"];

function hasMultipleLocations(location: string | null | undefined): boolean {
    if (location === "Oslo og omegn") {
        return true;
    }

    return false;
}
// empty urls will end up rendered as divs
function getTicketUrl(url: string | undefined | null, theatre: string, title: string): string {
    if (!url) return "";
    if (/\?/.test(url)) {
        return url + `&utm_source=Filmweb&utm_campaign=Filmweb&utm_medium=${theatre}&utm_content=${title}&sender=Filmweb`;
    } else {
        return url + `?utm_source=Filmweb&utm_campaign=Filmweb&utm_medium=${theatre}&utm_content=${title}&sender=Filmweb`;
    }
}

function isNFkino(theaterName: string) {
    let result = false;
    switch (theaterName) {
        case "Asker kino":
        case "Colosseum":
        case "Gimle":
        case "Klingenberg":
        case "Ringen":
        case "Saga":
        case "Symra":
        case "Vika":
            result = true;
            break;
    }
    return result;
}

function getScreenName(show: Show, location: string | null | undefined): string {
    if (hasMultipleLocations(location)) {
        if (isNFkino(show.theaterName!)) {
            return "NFKino " + show.screenName;
        }
        else {
            return show.theaterName + " - " + show.screenName;
        }
    }
    return show.screenName!;
}

function getVersionName(show: Show) {
    const versionTags = (show.versionTags?.filter((versionTag) => !EXCLUDE_TAGS.includes(versionTag!.tag!)) ?? []) as VersionTag[];
    versionTags.sort((a, b) => {
        if (a.tag!.endsWith(" tale") && !b.tag!.endsWith(" tale")) {
            return -1;
        }
        if (!a.tag!.endsWith(" tale") && b.tag!.endsWith(" tale")) {
            return 1;
        }

        if (a.tag!.includes("tekst") && !b.tag!.includes("tekst")) {
            return 1;
        }
        if (!a.tag!.includes("tekst") && b.tag!.includes("tekst")) {
            return -1;
        }
        return 0;
    });

    if (show.showType) versionTags.push({ tag: show.showType, type: "" });

    const versionText = versionTags.length === 0 ? "\u00A0" : versionTags.map(t => t.tag).join(", ");
    return versionText;
}

function trackSale(show: Show, genres: Genre[] | undefined) {
    window?.dataLayer?.push({
        'event': 'Billettsalg',
        'eventCategory': 'Billettsalg',
        'eventAction': `${show.firmName} ${show.theaterName}`,
        'eventLabel': show.movieTitle,
        'eventGenres': genres?.map(g => g.name)?.join(" / ") ?? "",
        'eventEdi': show.movieVersionId
    });
}
//#endregion

//#region [Styles]
type SIsKinoklubbProps = {
    $isKinoklubb?: boolean;
}

const SShow = styled(FilmwebLink) <SIsKinoklubbProps>`
	background-color: var(--BACKGROUNDCOLOR_OVERRIDE, var(--textcolor));
	color: var(--TEXTCOLOR_OVERRIDE, var(--textcolordark));
	width: 100%;
	//margin: 3px 0;
	display: flex;
	align-items: center;
	padding: 1px 15px;
    min-height: 44px;
	border-radius: 3px;
	cursor: pointer;
	border: none;
	text-align: left;
	text-decoration: none;

	@media ${theme.mq.desktop} {
		//@media (orientation: landscape) {
		//margin: 2px 0 3px 0;
		width: auto;
	}

	${props => props.$isKinoklubb ? css`
		background-color: var(--kinoklubbcolor);
	`: ""}

	&:not([href]) {
		pointer-events: none;
	}

`;

const STime = styled.div`
	font-size: 30px;
	font-weight: bold;
	min-width: 95px;
`;

const SKKTime = styled(STime)`
    &::after {
			content: "*";
	}
`;

const SScreenLine = styled.div`
	line-height: normal;
`;

type SScreenNameProps = {
    $isMultiLocation?: boolean;
};

const SScreenName = styled.div<SScreenNameProps>`
	font-weight: bold;

	${props => props.$isMultiLocation ? css`
		font-size: 14px;
	`: css`
		font-size: 18px;
	`}
`;

const SVersionInfo = styled.div`
	font-size: 14px;
`;

//#endregion