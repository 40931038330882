import LoadingIndicator from 'src/components/loadingindicator/LoadingIndicator';
import PosterRow from 'src/components/posterrow/PosterRow';
import { gql } from 'graphql-request';
import { buildFilminfoQueryKey, filminfoRequest, useFilminfo } from 'src/lib/client/useFilminfo';
import { Movie } from 'src/lib/movieinfo/movieinfotypes';
import styled from 'src/lib/styles/css';
import { QueryClient } from "@tanstack/react-query";
import Prefetch from "src/lib/server/reactQueryPrefetch";

//#region [Props]
type PopularMoviesListProps = {
    hideHeader?: boolean;
    className?: string;
};
//#endregion

// can be called by SSR to preload data for faster rendering
export const prefetch: Prefetch = async (queryClient) => queryClient.prefetchQuery({
    queryKey: buildFilminfoQueryKey(POP_MOVIES_QUERY),
    queryFn: filminfoRequest
});


//#region [Component]
export default function PopularMoviesList({ className, hideHeader = false }: PopularMoviesListProps) {
    const { fiLoading, fiData } = useFilminfo(POP_MOVIES_QUERY, undefined, { staleTime: 55 * 60 * 1000 }); // custom stale time since this is not critical data

    return <aside className={className}>
        {!hideHeader && <SPopMoviesHeader>Populære filmer</SPopMoviesHeader>}
        {fiLoading && <LoadingIndicator position='InlineCenter' />}
        {!fiLoading && <PosterRow posterList={fiData?.movieQuery?.getPopularMovies as (undefined | null | Movie[])} />}
    </aside>;
}
//#endregion

//#region [Styles]
const SPopMoviesHeader = styled.h2`
	color: var(--textcolor);
	margin: 10px 0;
`;
//#endregion

//#region [Other]
export const POP_MOVIES_QUERY = gql`
	query {
		movieQuery {
			getPopularMovies {
				__typename
				mainVersionId
				premiere
				title
				isKinoklubb
				genres
				lengthInMinutes
				rating
				sanityIdArt
            	sanityImagePosterUrl
            	sanityImageWideUrl
			  }
		}
	}
`;
//#endregion